import React, { useEffect, useState } from "react";
import { GetSubscribers } from "../api/services/backofficeServices";
import { Subscriber } from "../api/models/subscriberModel";
import { apiError } from "../api/models/error";
import {HasRole, IsAuthenticated } from "../api/services/authServices";
import { ActionType } from "../components/types";
import FrmSuscriptor from "../components/FrmSuscriptor";
import { Modal } from "react-bootstrap";

const Backoffice = () => {
  const [subscribers, setSubscribers] = useState<Subscriber[]>([]);
  const [currentAction, setCurrentAction] = useState<ActionType>("");
  const [editingSubscriber, setEditingSubscriber] = useState<Subscriber | null>(
    null
  );
  const [filter, setFilter] = useState<string | null>(null);

  const [canWrite, setCanWrite] = useState<boolean>(false);
  
  useEffect(() => {
    async function init() {
      const isAuthenticated = await IsAuthenticated();
      if (!isAuthenticated) {
        window.location.href = `/.auth/login/aad?post_login_redirect_uri=${window.location.href}`;
        return;
      }
      
      if(!(await HasRole("Settings.Read"))) {
        alert("No tiene permisos para acceder a esta página");
        window.location.href = "/";
        return;
      }
      
      setCanWrite( await HasRole("Settings.Write"));

      await loadSubscribers();
    
    }
    init();
  }, []);
  
  async function loadSubscribers() {
    const response = await GetSubscribers();
    if (
        typeof response === "object" &&
        response !== null &&
        "errorMessage" in response
    ) {
      const err = response as apiError;
      return;
    } else {
      setSubscribers(response as Subscriber[]);
      //console.log((response as Subscriber[]).length + " suscriptores");
    }
  }

  if (currentAction) {
    return (
      <FrmSuscriptor
        actionType={currentAction}
        subscriber={editingSubscriber}
        canWrite={canWrite}
        onClose={() => {
          setCurrentAction("");
          setEditingSubscriber(null);
        }}
        onSaved={async (subscriber) => {
          setCurrentAction("");
          setEditingSubscriber(null);
          await loadSubscribers();
        }}
      />
    );
  }

  function filterSubscribers() {
    if (!filter)
      return subscribers.sort((a, b) =>
        a.idSuscriptor.localeCompare(b.idSuscriptor)
      );

    return subscribers
      .filter(
        (s) =>
          s.idSuscriptor.toLowerCase().includes(filter.toLowerCase()) ||
          (s.nombre ? s.nombre : "")
            .toLowerCase()
            .includes(filter.toLowerCase())
      )
      .sort((a, b) => a.idSuscriptor.localeCompare(b.idSuscriptor));
  }

  return (
    <div className="container d-flex flex-column justify-content-center ">
      <div className="p-2 d-flex justify-content-end">
        <a href="/.auth/logout">Cerrar sesión</a>
      </div>
      <div className="panel">
        <span className="fs-3 text-center">Suscriptores</span>
        <div className="d-flex">
          <button
            className="btn btn-primary"
            disabled={!canWrite}
            onClick={() => setCurrentAction("add")}
          >
            Agregar
          </button>
          <input
            type="text"
            placeholder="Buscar"
            className="form-control ms-2"
            onChange={(e) => setFilter(e.target.value)}
          />
        </div>
        {subscribers.length === 0 && <p>No hay suscriptores</p>}

        <table className="table table-hover">
          <thead>
            <tr>
              <th>Id</th>
              <th>Nombre</th>
            </tr>
          </thead>
          <tbody>
            {filterSubscribers().map((s) => (
              <tr>
                <td
                  onClick={() => {
                    setCurrentAction("edit");
                    setEditingSubscriber(s);
                  }}
                >
                  {s.idSuscriptor}
                </td>
                <td
                  onClick={() => {
                    setCurrentAction("edit");
                    setEditingSubscriber(s);
                  }}
                >
                  {s.nombre}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Backoffice;
