import axios from "axios";
import { getBackofficeAPIUrl } from "../helpers/apiHelpers";
import { apiError } from "../models/error";
import { Subscriber } from "../models/subscriberModel";

export const GetSubscribers = async (): Promise<Subscriber[] | apiError> => {
  try {
    const response = await axios.get(getBackofficeAPIUrl());
    return response.data;
  } catch (error) {
    console.log("err", error);
    return { errorMessage: "error" };
  }
};

export const PostSubscriber = async (
  subscriber: FormData
): Promise<Subscriber | apiError> => {
  try {
    const idSuscriptor = subscriber.get("idSuscriptor");

    const response = await axios.post(
      getBackofficeAPIUrl(idSuscriptor as string),
      subscriber,
      {
        headers: {
          "Content-Type": "multipart/form-data", // Indicar tipo de contenido
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log("err", error);
    return { errorMessage: "error" };
  }
};
