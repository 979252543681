import { Alert, Button, Form, Modal } from "react-bootstrap";
import { Subscriber } from "../api/models/subscriberModel";
import { ActionType } from "./types";
import { useEffect, useState } from "react";
import { PostSubscriber } from "../api/services/backofficeServices";

interface Props {
  actionType: ActionType;
  subscriber: Subscriber | null;
  canWrite: boolean;
  onClose: () => void;
  onSaved: (subscriber: Subscriber) => void;
}
const FrmSuscriptor = ({ actionType, subscriber, canWrite, onClose, onSaved }: Props) => {
  const [formSubscriber, setFormSubscriber] = useState<Subscriber>({
    nombre: "",
    idSuscriptor: "",
    webAPIUrl: "",
    userName:"",
    password:"",
    logoUrl: "",
  });

  const [image, setImage] = useState<Blob | MediaSource>();

  const [error, setError] = useState<string | null>(null);
  
  const [confirmPassword, setConfirmPassword] = useState<string | null>(null);

  useEffect(() => {
    if (subscriber)      setFormSubscriber(subscriber);
          
  }, [subscriber]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if(event.target.name === "confirm-password") {
      setConfirmPassword(event.target.value);
      return;
    }
    
    setFormSubscriber({
      ...formSubscriber,
      [event.target.name]: event.target.value,
    });
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      setImage(event.target.files[0]);
    }
  };

  const handleSubmit = async () => {
    setError(null);

    if (!formSubscriber.idSuscriptor) {
      setError("El código es obligatorio");
      return;
    }

    if (!formSubscriber.nombre) {
      setError("El nombre es obligatorio");
      return;
    }

    if (!formSubscriber.webAPIUrl) {
      setError("La URL de la API es obligatoria");
      return;
    }
    
    if (!formSubscriber.userName) {
      setError("El usuario es obligatorio");
      return;
    }
    
      if (!formSubscriber.password) {
        setError("La contraseña es obligatoria");
        return;
      }
    
    if ( formSubscriber.password !== confirmPassword) {
      setError("Las contraseñas no coinciden");
      return;
    }

    // Convert form data to FormData for file upload
    const formData = new FormData();
    formData.append("nombre", formSubscriber.nombre);
    formData.append("idSuscriptor", formSubscriber.idSuscriptor);
    formData.append("webAPIUrl", formSubscriber.webAPIUrl);
        
    const auth = btoa(`${formSubscriber.userName}:${formSubscriber.password}`);
    formData.append("auth", `Basic ${auth}`);
    formData.append("userName", formSubscriber.userName);
    
    formData.append("logo", image as Blob);
    const response = await PostSubscriber(formData);

    onSaved(response as Subscriber);
  };

  return (
    <Modal show={true}>
      <Modal.Header>
        <span className="fs-3">
          {actionType === "add" ? "Nuevo suscriptor" : "Editar suscriptor"}
        </span>
      </Modal.Header>

      <div className="p-3">
        <Form>
          <Form.Group controlId="formName">
            <Form.Label>Código</Form.Label>
            <Form.Control
              type="text"
              name="idSuscriptor"
              disabled={!canWrite}
              value={formSubscriber.idSuscriptor || ""}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group controlId="formName">
            <Form.Label>Nombre</Form.Label>
            <Form.Control
              type="text"
              name="nombre"
              disabled={!canWrite}
              value={formSubscriber.nombre || ""}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group controlId="formName">
            <Form.Label>WebAPI</Form.Label>
            <Form.Control
              type="text"
              name="webAPIUrl"
              disabled={!canWrite}
              value={formSubscriber.webAPIUrl || ""}
              onChange={handleChange}
            />
          </Form.Group>
          
          <Form.Group controlId="formName">
            <Form.Label>Usuario:</Form.Label>
            <Form.Control type="text" name="userName" value={formSubscriber.userName || ""} disabled={!canWrite} onChange={handleChange}/>
          </Form.Group>

          <Form.Group controlId="formName">
            <Form.Label>Contraseña:</Form.Label>
            <Form.Control type="password" name="password" value={formSubscriber.password || ""} disabled={!canWrite} onChange={handleChange}/>
          </Form.Group>

          <Form.Group controlId="formName">
            <Form.Label>Confirmar contraseña:</Form.Label>
            <Form.Control type="password" name="confirm-password" value={confirmPassword || ""} disabled={!canWrite} onChange={handleChange}/>
          </Form.Group>



          <Form.Group controlId="formFile">
            <Form.Label>Logotipo</Form.Label>
            <Form.Control type="file" accept=".png" name="logo" disabled={!canWrite} onChange={handleFileChange} />
          </Form.Group>

          
          {error && (
            <Alert variant="danger" className="mt-3">
              {error}
            </Alert>
          )}
        </Form>
      </div>

      <Modal.Footer>
        <Button variant="secondary" onClick={() => onClose()}>
          Cancelar
        </Button>{" "}
        <Button variant="success" disabled={!canWrite} onClick={handleSubmit}>
          Guardar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default FrmSuscriptor;
