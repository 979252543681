import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";

import "./styles/scss/stylesheet.scss";

import { BrowserRouter, NavLink, Route, Routes } from "react-router-dom";

// Must declare <base href="%PUBLIC_URL%/" /> at index.html
const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href");
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  // <React.StrictMode>
    <BrowserRouter basename={!baseUrl ? undefined : baseUrl}>
      <App />
    </BrowserRouter>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
