import axios from "axios";
import { getGlobalAPIUrl } from "../helpers/apiHelpers";

export const IsAuthenticated = async (): Promise<boolean> => {
  try {
    const response = await axios.get(getGlobalAPIUrl("authuser"));
    const { data } = response;
    
    if (data.userName) return true;
    console.log("No hay usuario logueado");
    return false;
  } catch (error) {
    console.log("err", error);
    return false;
  }
};

export const HasRole = async (role: string): Promise<boolean> => {
  try {
    const response = await axios.get(getGlobalAPIUrl("authuser"));
    const { data } = response;
       
    if (data.roles.includes(role)) return true;
    console.log("No tiene el rol", role);
    return false;
  } catch (error) {
    console.log("err", error);
    return false;
  }
}
